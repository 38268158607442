$primary: #fb642d;
$secondary: #7b7b7b;
$warning: #ffc164;
$info: #00b0ff;
$danger: #f3033c;
/*
$btn-border-radius: 50px;
$btn-border-radius-lg: 70px;
$btn-border-radius-sm: 30px;
$border-radius: 8px;
$border-radius-sm: 6px;
$border-radius-lg: 8px;
$alert-bg-level: 0;
$alert-border-level: 0;
$input-border-radius: 14px;
*/
$breadcrumb-divider: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+');
$custom-file-text: (
	pl: "Przeglądaj",
	en: "Browse",
	de: "Durchsuche",
	ru: "Просматривать",
	fr: "Feuilleter",
	es: "Elegir",
	pt: "Squeaky toy",
	sk: "Prechádzať",
	cs: "Procházet",
	hu: "Tallózás",
	lt: "Naršyti",
	lv: "Pārlūkot",
	et: "Sirvige",
	uk: "Перегляньте",
	ro: "Naviga",
	da: "Gennemse",
	nl: "Bladeren",
	it: "Navigare",
	sv: "Bläddra",
	fi: "Selaa",
	no: "Bla gjennom"
);

