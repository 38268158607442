$btn-border-radius: 50px;
$btn-border-radius-lg: 70px;
$btn-border-radius-sm: 30px;
$border-radius: 8px;
$border-radius-sm: 6px;
$border-radius-lg: 8px;
$alert-bg-level: 0;
$alert-border-level: 0;
$input-border-radius: 14px;
$btn-border-radius: 50px;
$btn-border-radius-lg: 70px;
$btn-border-radius-sm: 30px;
$border-radius: 8px;
$border-radius-sm: 6px;
$border-radius-lg: 8px;
$alert-bg-level: 0;
$alert-border-level: 0;
$input-border-radius: 14px;

:root {
	--bs-primary: #fb642d;
	--bs-secondary: #7b7b7b;
	--bs-success: #00a69a;
	--bs-info: #00b0ff;
	--bs-warning: #ffc164;
	--bs-danger: #f3033c;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	
	--bs-primary-rgb: 251,100,45;
	--bs-secondary-rgb: 123,123,123;
	--bs-success-rgb: 0,166,154;
	--bs-info-rgb: 0,176,255;
	--bs-warning-rgb: 255,193,100;
	--bs-danger-rgb: 243,3,60;
	--bs-light-rgb: 248,249,250;
	--bs-dark-rgb: 33,37,41;
}

[data-bs-theme="theme390"] {
	$input-border-radius: 5px;
	$btn-border-radius: 5px;
	$btn-border-radius-lg: 7px;
	$btn-border-radius-sm: 3px;
	$border-radius: 3px;
	$border-radius-lg: 3px;
	$border-radius-sm: 1px;

	--bs-primary: #111111;
	--bs-primary-secondary: #222222;
	--bs-secondary: #333333;
	--bs-secondary-secondary: #444444;
	
	.btn.btn-link {
		&:hover {
			color: var(--bs-primary-secondary);
		}
	}

	a {
		&:hover {
			color: var(--bs-primary-secondary);
		}
	}

	.text-primary {
		color: var(--bs-primary-secondary) !important;
	}


	.btn-primary {
		--bs-btn-bg: var(--bs-primary);
		--bs-btn-border-color: var(--bs-primary);
		--bs-btn-hover-bg: var(--bs-primary-secondary);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-primary-secondary);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	.btn-secondary {
		color: #fff;
		--bs-btn-bg: var(--bs-secondary);
		--bs-btn-border-color: var(--bs-secondary);
		--bs-btn-hover-bg: var(--bs-secondary-secondary);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-secondary);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-success {
		color: #fff;
		--bs-btn-bg: var(--bs-success);
		--bs-btn-border-color: var(--bs-success);
		--bs-btn-hover-bg: var(--bs-secondary-success);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-success);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-info {
		color: #fff;
		--bs-btn-bg: var(--bs-info);
		--bs-btn-border-color: var(--bs-info);
		--bs-btn-hover-bg: var(--bs-secondary-info);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-info);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-warning {
		color: #fff;
		--bs-btn-bg: var(--bs-warning);
		--bs-btn-border-color: var(--bs-warning);
		--bs-btn-hover-bg: var(--bs-secondary-warning);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-warning);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-danger {
		color: #fff;
		--bs-btn-bg: var(--bs-danger);
		--bs-btn-border-color: var(--bs-danger);
		--bs-btn-hover-bg: var(--bs-secondary-danger);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-danger);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: var(--bs-primary);
		--bs-btn-border-color: var(--bs-primary);
		--bs-btn-hover-bg: var(--bs-primary);
		--bs-btn-hover-border-color: var(--bs-primary);
		--bs-btn-active-bg: var(--bs-primary);
		--bs-btn-active-border-color: var(--bs-primary);
		--bs-btn-disabled-border-color: var(--bs-primary);
		--bs-btn-disabled-color: var(--bs-primary);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-secondary {
		--bs-btn-color: var(--bs-secondary);
		--bs-btn-border-color: var(--bs-secondary);
		--bs-btn-hover-bg: var(--bs-secondary);
		--bs-btn-hover-border-color: var(--bs-secondary);
		--bs-btn-active-bg: var(--bs-secondary);
		--bs-btn-active-border-color: var(--bs-secondary);
		--bs-btn-disabled-border-color: var(--bs-secondary);
		--bs-btn-disabled-color: var(--bs-secondary);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-success {
		--bs-btn-color: var(--bs-success);
		--bs-btn-border-color: var(--bs-success);
		--bs-btn-hover-bg: var(--bs-success);
		--bs-btn-hover-border-color: var(--bs-success);
		--bs-btn-active-bg: var(--bs-success);
		--bs-btn-active-border-color: var(--bs-success);
		--bs-btn-disabled-border-color: var(--bs-success);
		--bs-btn-disabled-color: var(--bs-success);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-info {
		--bs-btn-color: var(--bs-info);
		--bs-btn-border-color: var(--bs-info);
		--bs-btn-hover-bg: var(--bs-info);
		--bs-btn-hover-border-color: var(--bs-info);
		--bs-btn-active-bg: var(--bs-info);
		--bs-btn-active-border-color: var(--bs-info);
		--bs-btn-disabled-border-color: var(--bs-info);
		--bs-btn-disabled-color: var(--bs-info);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-warning {
		--bs-btn-color: var(--bs-warning);
		--bs-btn-border-color: var(--bs-warning);
		--bs-btn-hover-bg: var(--bs-warning);
		--bs-btn-hover-border-color: var(--bs-warning);
		--bs-btn-active-bg: var(--bs-warning);
		--bs-btn-active-border-color: var(--bs-warning);
		--bs-btn-disabled-border-color: var(--bs-warning);
		--bs-btn-disabled-color: var(--bs-warning);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-danger {
		--bs-btn-color: var(--bs-danger);
		--bs-btn-border-color: var(--bs-danger);
		--bs-btn-hover-bg: var(--bs-danger);
		--bs-btn-hover-border-color: var(--bs-danger);
		--bs-btn-active-bg: var(--bs-danger);
		--bs-btn-active-border-color: var(--bs-danger);
		--bs-btn-disabled-border-color: var(--bs-danger);
		--bs-btn-disabled-color: var(--bs-danger);
		&:hover {
			color: #fff;
		}
	}
	
	.btn-link {
		&:hover {
			text-decoration: underline !important;
		}
	}
	
}

[data-bs-theme="test"] {
	$input-border-radius: 5px;
	$btn-border-radius: 5px;
	$btn-border-radius-lg: 7px;
	$btn-border-radius-sm: 3px;
	$border-radius: 3px;
	$border-radius-lg: 3px;
	$border-radius-sm: 1px;

	--bs-primary: violet;
	--bs-primary-secondary: #9400D3;
	--bs-secondary: green;
	--bs-secondary-secondary: #006400;
	--bs-success: blue;
	--bs-secondary-success: #00008B;
	--bs-info: turquoise;
	--bs-secondary-info: #00CED1;
	--bs-warning: purple;
	--bs-secondary-warning: #8B008B;
	--bs-danger: #A0522D;
	--bs-secondary-danger: #8B4513;
	
	--bs-btn-radius: 10px;

	.btn {
		--bs-btn-border-radius: var(--bs-btn-radius);
	}

	.btn.btn-link {
		&:hover {
			color: var(--bs-primary-secondary);
		}
	}

	a {
		&:hover {
			color: var(--bs-primary-secondary);
		}
	}

	.text-primary {
		color: var(--bs-primary-secondary) !important;
	}


	.btn-primary {
		--bs-btn-bg: var(--bs-primary);
		--bs-btn-border-color: var(--bs-primary);
		--bs-btn-hover-bg: var(--bs-primary-secondary);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-primary-secondary);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	.btn-secondary {
		color: #fff;
		--bs-btn-bg: var(--bs-secondary);
		--bs-btn-border-color: var(--bs-secondary);
		--bs-btn-hover-bg: var(--bs-secondary-secondary);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-secondary);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-success {
		color: #fff;
		--bs-btn-bg: var(--bs-success);
		--bs-btn-border-color: var(--bs-success);
		--bs-btn-hover-bg: var(--bs-secondary-success);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-success);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-info {
		color: #fff;
		--bs-btn-bg: var(--bs-info);
		--bs-btn-border-color: var(--bs-info);
		--bs-btn-hover-bg: var(--bs-secondary-info);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-info);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-warning {
		color: #fff;
		--bs-btn-bg: var(--bs-warning);
		--bs-btn-border-color: var(--bs-warning);
		--bs-btn-hover-bg: var(--bs-secondary-warning);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-warning);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-danger {
		color: #fff;
		--bs-btn-bg: var(--bs-danger);
		--bs-btn-border-color: var(--bs-danger);
		--bs-btn-hover-bg: var(--bs-secondary-danger);
		--bs-btn-hover-border-color: #{rgba($white, .25)};
		--bs-btn-active-bg: var(--bs-secondary-danger);
		--bs-btn-active-border-color: #{rgba($white, .5)};
		--bs-btn-focus-border-color: #{rgba($white, .5)};
		--bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: var(--bs-primary);
		--bs-btn-border-color: var(--bs-primary);
		--bs-btn-hover-bg: var(--bs-primary);
		--bs-btn-hover-border-color: var(--bs-primary);
		--bs-btn-active-bg: var(--bs-primary);
		--bs-btn-active-border-color: var(--bs-primary);
		--bs-btn-disabled-border-color: var(--bs-primary);
		--bs-btn-disabled-color: var(--bs-primary);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-secondary {
		--bs-btn-color: var(--bs-secondary);
		--bs-btn-border-color: var(--bs-secondary);
		--bs-btn-hover-bg: var(--bs-secondary);
		--bs-btn-hover-border-color: var(--bs-secondary);
		--bs-btn-active-bg: var(--bs-secondary);
		--bs-btn-active-border-color: var(--bs-secondary);
		--bs-btn-disabled-border-color: var(--bs-secondary);
		--bs-btn-disabled-color: var(--bs-secondary);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-success {
		--bs-btn-color: var(--bs-success);
		--bs-btn-border-color: var(--bs-success);
		--bs-btn-hover-bg: var(--bs-success);
		--bs-btn-hover-border-color: var(--bs-success);
		--bs-btn-active-bg: var(--bs-success);
		--bs-btn-active-border-color: var(--bs-success);
		--bs-btn-disabled-border-color: var(--bs-success);
		--bs-btn-disabled-color: var(--bs-success);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-info {
		--bs-btn-color: var(--bs-info);
		--bs-btn-border-color: var(--bs-info);
		--bs-btn-hover-bg: var(--bs-info);
		--bs-btn-hover-border-color: var(--bs-info);
		--bs-btn-active-bg: var(--bs-info);
		--bs-btn-active-border-color: var(--bs-info);
		--bs-btn-disabled-border-color: var(--bs-info);
		--bs-btn-disabled-color: var(--bs-info);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-warning {
		--bs-btn-color: var(--bs-warning);
		--bs-btn-border-color: var(--bs-warning);
		--bs-btn-hover-bg: var(--bs-warning);
		--bs-btn-hover-border-color: var(--bs-warning);
		--bs-btn-active-bg: var(--bs-warning);
		--bs-btn-active-border-color: var(--bs-warning);
		--bs-btn-disabled-border-color: var(--bs-warning);
		--bs-btn-disabled-color: var(--bs-warning);
		&:hover {
			color: #fff;
		}
	}
	.btn-outline-danger {
		--bs-btn-color: var(--bs-danger);
		--bs-btn-border-color: var(--bs-danger);
		--bs-btn-hover-bg: var(--bs-danger);
		--bs-btn-hover-border-color: var(--bs-danger);
		--bs-btn-active-bg: var(--bs-danger);
		--bs-btn-active-border-color: var(--bs-danger);
		--bs-btn-disabled-border-color: var(--bs-danger);
		--bs-btn-disabled-color: var(--bs-danger);
		&:hover {
			color: #fff;
		}
	}
	.btn-link {
		&:hover {
			text-decoration: underline !important;
		}
	}
	
	
	// header and footer
	--bs-navbar-super-top: silver;
	--bs-footer-nav: gold;
	--bs-footer-copy: pink;
	
	.navbar-supertop {
		background-color: var(--bs-navbar-super-top)
	}
	
	footer {
		.footer-nav {
			background-color: var(--bs-footer-nav)
		}

		.footer-copy {
			background-color: var(--bs-footer-copy)
		}
	}
}

